import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiService, HttpError, NotAuthorizedError, TApiFetchResponse } from 'web_core_library';
import * as ActionTypes from './actionTypes';
import AuthService from './authService';
import CredentialsService from './credentialsService';
import { PasskeyAuthActions } from './slice';
import {
  AuthenticationCredential,
  RegistrationCredential,
  TFailureReason,
  TSendRegistrationPayload,
  TSendUpgradePayload,
} from './types';
import { getDeviceName, parseAuthenticationResponse, parseRegistrationResponse } from './utils';

export default function* passkeyAuthWatcher() {
  yield all([
    takeEvery(PasskeyAuthActions.initPasskey, initFeatureSaga),
    takeLeading(PasskeyAuthActions.startLogin, handleLoginSaga),
    takeLeading(PasskeyAuthActions.startRegistration, handleRegistrationSaga),
    takeLeading(PasskeyAuthActions.requestListOfPasskeys, handleLoadingOfPasskeysSaga),
    takeLeading(PasskeyAuthActions.deleteUsersPasskey, deleteUsersPasskeySaga),
    takeLeading(PasskeyAuthActions.upgradeUserToPasskey, upgradeUserToPasskeySaga),
  ]);
}

export function* initFeatureSaga() {
  yield call(CredentialsService.init, ApiService);
  const isSupported: boolean = yield call(AuthService.isBrowserSupported);
  yield put(PasskeyAuthActions.featureReady(isSupported));
}

export function* handleLoginSaga() {
  try {
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getLoginCredentials> = yield call(
      CredentialsService.getLoginCredentials
    );
    const browserCredentials: AuthenticationCredential | null = yield call(
      AuthService.loginWithPasskey,
      credentialsFromApi.data
    );
    if (!browserCredentials) {
      yield put(PasskeyAuthActions.loginFailed({ message: 'Credentials empty!', reason: 'abort' }));
      return;
    }
    const parsedCredentials: ReturnType<typeof parseAuthenticationResponse> = yield call(
      parseAuthenticationResponse,
      browserCredentials
    );
    const result: TApiFetchResponse<typeof CredentialsService.sendLoginCredentials> = yield call(
      CredentialsService.sendLoginCredentials,
      parsedCredentials
    );
    yield put(PasskeyAuthActions.loginSuccess(result.data.result));
  } catch (error) {
    console.error(error);
    let reason: TFailureReason = 'unknown';
    if (error instanceof NotAuthorizedError) {
      reason = 'auth';
    } else if (error instanceof DOMException) {
      reason = 'abort';
    }
    yield put(PasskeyAuthActions.loginFailed({ message: (error as Error).message, reason }));
  }
}

export function* handleRegistrationSaga({ payload }: ActionTypes.TStartRegistrationAction) {
  try {
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getRegistrationCredentials> = yield call(
      CredentialsService.getRegistrationCredentials,
      payload.email
    );
    const browserCredentials: RegistrationCredential | null = yield call(
      AuthService.createNewPasskey,
      credentialsFromApi.data
    );
    if (!browserCredentials) {
      yield put(PasskeyAuthActions.registrationFailed({ message: 'Credentials empty!', reason: 'unknown' }));
      return;
    }
    const parsedCredentials: ReturnType<typeof parseRegistrationResponse> = yield call(
      parseRegistrationResponse,
      browserCredentials
    );
    const { email, language, referTo, attribution, couponToken } = payload;
    const regPayload: TSendRegistrationPayload = {
      ...parsedCredentials,
      language,
      referTo,
      attribution,
      couponToken,
      email,
      deviceName: getDeviceName(),
    };
    const result: TApiFetchResponse<typeof CredentialsService.sendRegistrationCredentials> = yield call(
      CredentialsService.sendRegistrationCredentials,
      regPayload
    );
    yield put(PasskeyAuthActions.registrationDone(result.data.result));
  } catch (error) {
    console.error(error);
    const reason: TFailureReason = error instanceof DOMException ? 'abort' : 'unknown';
    // try to get message from response fallback to base error message otherwise
    const message = (error as HttpError).data?.message ?? (error as Error).message;
    yield put(PasskeyAuthActions.registrationFailed({ message, reason }));
  }
}

export function* handleLoadingOfPasskeysSaga() {
  try {
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getListOfPasskeys> = yield call(
      CredentialsService.getListOfPasskeys
    );
    yield put(PasskeyAuthActions.updateListOfPasskeys(credentialsFromApi.data));
  } catch (error) {
    console.error(error);
    // TODO: implement error handling
  }
}

export function* deleteUsersPasskeySaga({ payload }: ActionTypes.TDeleteUsersPasskeyAction) {
  try {
    yield call(CredentialsService.deleteUsersPasskey, payload);
    yield put(PasskeyAuthActions.deleteUsersPasskeySuccess(payload));
  } catch (error) {
    console.error(error);
    // TODO: implement error handling
  }
}

export function* upgradeUserToPasskeySaga() {
  try {
    const apiCredentials: TApiFetchResponse<typeof CredentialsService.requestUserUpgrade> = yield call(
      CredentialsService.requestUserUpgrade
    );
    const browserCredentials: RegistrationCredential | null = yield call(
      AuthService.createNewPasskey,
      apiCredentials.data
    );
    if (!browserCredentials) {
      yield put(
        PasskeyAuthActions.upgradeUserToPasskeyFailed({ message: 'Empty credentials received!', reason: 'unknown' })
      );
      return;
    }
    const parsedCredentials: ReturnType<typeof parseRegistrationResponse> = yield call(
      parseRegistrationResponse,
      browserCredentials
    );
    const regPayload: TSendUpgradePayload = {
      ...parsedCredentials,
      deviceName: getDeviceName(),
    };
    yield call(CredentialsService.saveUserPasskeyUpgrade, regPayload);
    yield put(PasskeyAuthActions.upgradeUserToPasskeySuccess());
    yield put(PasskeyAuthActions.requestListOfPasskeys());
  } catch (error) {
    console.error(error);
    const reason: TFailureReason = error instanceof DOMException ? 'abort' : 'unknown';
    yield put(PasskeyAuthActions.upgradeUserToPasskeyFailed({ message: (error as Error).message, reason }));
  }
}
