import { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { unixTimestampToDate } from '../../common/dateUtils';
import { NotificationActions } from '../notifications';
import { analyticsEventAction, messageClickedAction, messageShownAction } from './actions';
import * as MessagingService from './messaging';
import { getMessages, isMessagingActive } from './selectors';

export default function usePushMessages() {
  const dispatch = useDispatch<Dispatch<Action<string>>>();
  const isEnabled = useSelector(isMessagingActive);
  const messages = useSelector(getMessages);
  useEffect(() => {
    if (!isEnabled || !messages || !messages.length) {
      return;
    }
    messages.forEach((m) => {
      const clickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        const userId = MessagingService.getUserId() ?? 0;
        dispatch(messageClickedAction(m.messageId));
        dispatch(
          analyticsEventAction({
            eventName: 'web_push_clicked',
            parameters: [
              { key: 'User ID', value: userId },
              { key: 'Date', value: unixTimestampToDate().toISOString() },
              { key: 'Message ID', value: m.messageId },
            ],
          })
        );
        const el = e.target;
        if (el instanceof HTMLAnchorElement) {
          const href = el.getAttribute('href');
          if (href) {
            e.preventDefault();
            setTimeout(() => {
              window.location.assign(href);
            }, 500);
          }
        }
      };
      dispatch(NotificationActions.showPushMessage(m.body, m.title, m.link, clickHandler));
      dispatch(messageShownAction(m));
    });
  }, [dispatch, isEnabled, messages]);
}
