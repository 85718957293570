import React from 'react';
import './StringInput.scss';

export enum EInputColor {
  Light = 'light',
  Dark = 'dark',
}

export interface IStringInputProps {
  autocomplete?: string;
  autofocus?: boolean;
  className?: string;
  color?: EInputColor;
  error?: string;
  initialValue?: string;
  name?: string;
  placeholder: string;
  required?: boolean;
  type?: string;
  onChange: (value: string) => void;
}

const StringInput: React.FC<React.PropsWithChildren<IStringInputProps>> = ({
  children,
  autocomplete,
  autofocus,
  className,
  color = EInputColor.Dark,
  error,
  initialValue,
  name,
  placeholder,
  required,
  type = 'text',
  onChange,
}) => {
  const errorClass = error ? ' is-invalid' : '';
  const inputRef = React.createRef<HTMLInputElement>();
  // autofocus on open
  React.useEffect(() => {
    if (autofocus && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [autofocus, inputRef]);
  const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => onChange(e.target.value);
  const blurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => onChange(e.target.value);
  return (
    <div className={`string-input-container${errorClass} ${color} ${className}`}>
      <div className="input-wrapper">
        <input
          name={name}
          type={type}
          ref={inputRef}
          onChange={changeHandler}
          defaultValue={initialValue}
          className="string-input"
          placeholder={placeholder}
          required={required}
          onBlur={blurHandler}
          autoComplete={autocomplete}
        />
        {children}
      </div>
      <div className="error-feedback">{error ?? null}</div>
    </div>
  );
};

export default StringInput;
