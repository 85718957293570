import { Button, Dialog, EButtonColor } from '@web_opxp/components';
import { useTranslation } from '../language';
import './PasskeyConfirmation.scss';

interface IPasskeyConfirmationProps {
  show: boolean;
  onConfirm: () => void;
  onDecline?: () => void;
  onPostpone: () => void;
}

export default function PasskeyConfirmation({ show, onConfirm, onDecline, onPostpone }: IPasskeyConfirmationProps) {
  const { t } = useTranslation();
  if (!show) {
    return null;
  }
  const method1Label = t('opxp.common:Passkey.Confirmation.Method1');
  const method2Label = t('opxp.common:Passkey.Confirmation.Method2');
  const method3Label = t('opxp.common:Passkey.Confirmation.Method3');
  return (
    <Dialog
      isOpen={show}
      bodyClassName="passkey-confirmation-dialog"
      showCloseButton={true}
      onClose={onPostpone}
      canOutsideClickClose={false}
      canEscapeKeyClose={false}
      autoFocus={true}
      headerContent={<h3 className="bp5-heading">{t('opxp.common:Passkey.Confirmation.Title')}</h3>}>
      <p>{t('opxp.common:Passkey.Confirmation.Description')}</p>
      <ul>
        <li>
          <img src="https://cms.cdn.neuronation.com/assets/opxp/feature/icons/passkey/face-id.svg" alt={method1Label} />
          {method1Label}
        </li>
        <li>
          <img
            src="https://cms.cdn.neuronation.com/assets/opxp/feature/icons/passkey/fingerprint.svg"
            alt={method2Label}
          />
          {method2Label}
        </li>
        <li>
          <img
            src="https://cms.cdn.neuronation.com/assets/opxp/feature/icons/passkey/mdi_keypad.svg"
            alt={method3Label}
          />
          {method3Label}
        </li>
      </ul>
      <div className="action-buttons">
        <Button label={t('opxp.common:Passkey.Confirmation.Confirm')} color={EButtonColor.blue} onClick={onConfirm} />
        <Button
          label={t('opxp.common:Passkey.Confirmation.Postpone')}
          color={EButtonColor.white}
          onClick={onPostpone}
        />
        {onDecline ? (
          <Button
            label={t('opxp.common:Passkey.Confirmation.Decline')}
            color={EButtonColor.white}
            onClick={onDecline}
          />
        ) : null}
      </div>
    </Dialog>
  );
}
