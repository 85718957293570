import { MessagePayload } from 'firebase/messaging';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { unixTimestampToDate } from '../../common/dateUtils';
import { analyticsEventAction, messageReceivedAction } from './actions';
import * as MessagingService from './messaging';
import { isMessagingActive } from './selectors';

export interface IMessagingControllerProps {
  getInternalUrl: () => string;
}

export default function MessagingController<T extends Dispatch<Action<string>>>({
  getInternalUrl,
}: IMessagingControllerProps) {
  const dispatch = useDispatch<T>();
  const enabled = useSelector(isMessagingActive);
  const messageListener = useCallback(
    (payload: MessagePayload) => {
      const { title = '', body = '' } = payload.notification || {};
      // Don't change the following to ?? because link can be empty string
      const link = payload.fcmOptions?.link || getInternalUrl();
      const messageId = payload.messageId;
      dispatch(messageReceivedAction({ messageId, title, body, link }));
      const userId = MessagingService.getUserId() ?? 0;
      dispatch(
        analyticsEventAction({
          eventName: 'web_push_received',
          parameters: [
            { key: 'User ID', value: userId },
            { key: 'Date', value: unixTimestampToDate().toISOString() },
            { key: 'Message ID', value: messageId },
          ],
        })
      );
    },
    [dispatch, getInternalUrl]
  );
  useEffect(() => {
    if (!enabled) {
      return;
    }
    console.info('MessagingController started');
    const unsubscribe = MessagingService.startMessaging(messageListener);
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [enabled, messageListener]);
  return null;
}
