import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import { PROGRESS_HISTORY_LOADING_LIMIT } from './constants';
import ProgressDb from './progressDb';
import ProgressService from './progressService';
import * as Selectors from './selectors';
import { ProgressActions } from './slice';
import {
  IBaseResult,
  ILoadLastProgressPayload,
  ILoadProgressHistoryPayload,
  ILoadworkoutProgressPayload,
  ILocalResult,
  IProgressCacheValue,
  ISaveProgressPayload,
  ISaveProgressScorePayload,
} from './types';
import {
  groupProgressByWorkout,
  isFullProgressPage,
  markDataAsSynchronized,
  mergeProgressFromApiToLocal,
  parseProgress,
  prepareProgressForSaving,
  resultBuilder,
  scoreBuilder,
  sortProgress,
} from './utils';

export function* initProgressSaga(action: PayloadAction<number>) {
  const userId = action.payload;
  yield call(ProgressService.init, ApiService);
  yield call(ProgressDb.init, userId);
  yield put(ProgressActions.progressReady());
}

export function* waitForFullProgressSaga() {
  // we must wait until cache and api services are initialized
  const isReady: boolean = yield select(Selectors.isProgressReady);
  if (!isReady) {
    yield take(ProgressActions.progressReady);
  }
  // we must wait if loading process not finished yet
  const isLoading: boolean = yield select(Selectors.isProgressLoading);
  if (isLoading) {
    yield take(ProgressActions.progressLoaded);
  }
}

export function* loadProgressSaga(action: PayloadAction<number | undefined>) {
  const userId = action.payload;
  const progressLoaded: boolean = yield select(Selectors.isFullProgressLoaded, userId);
  if (progressLoaded) {
    yield put(ProgressActions.progressLoaded());
    return;
  }
  // check data in cache
  const cacheValue: IProgressCacheValue | undefined = yield call(ProgressDb.getProgress, userId);
  if (!cacheValue) {
    // if no data in cache - we need to load it from backend
    yield call(loadAndCacheFullProgress, userId);
    return;
  }
  if (userId) {
    // last activity not implemented for custom user id currently
    // so we need to load progress regardless of last activity
    yield call(loadAndCacheProgressFromTimestamp, cacheValue.progress, cacheValue.timestamp, userId);
    return;
  }
  const lastActivity: number = yield call(ProgressService.getProgressLastActivityTimestamp);
  if (lastActivity > cacheValue.timestamp) {
    // if last activity timestamp is greater than of last progress we have in cache
    // then we need to load missing progress starting from timestamp we have saved
    yield call(loadAndCacheProgressFromTimestamp, cacheValue.progress, cacheValue.timestamp, userId);
    return;
  }
  yield put(ProgressActions.updateProgress(cacheValue.progress, userId));
  yield put(ProgressActions.progressLoaded());
}

export function* loadAndCacheFullProgress(userId?: number) {
  // load progress from api
  const apiProgress: IBaseResult[] = yield call(loadFullProgressFromApi, undefined, undefined, userId);
  // convert to local data
  const progress: ILocalResult[] = yield call(parseProgress, apiProgress);
  // save it to the cache
  yield call(ProgressDb.saveProgress, progress, userId);
  // send to the state
  yield put(ProgressActions.updateProgress(progress, userId));
  yield put(ProgressActions.progressLoaded());
}

export function* loadAndCacheProgressFromTimestamp(
  existingProgress: ILocalResult[],
  timestamp: number,
  userId?: number
) {
  // load missing progress from api
  const apiProgress: IBaseResult[] = yield call(loadFullProgressFromApi, timestamp, undefined, userId);
  // merge it with existing progress
  const progress: ILocalResult[] = yield call(mergeProgressFromApiToLocal, apiProgress, existingProgress);
  // save it to the cache
  yield call(ProgressDb.saveProgress, progress, userId);
  // send to the state
  yield put(ProgressActions.updateProgress(progress, userId));
  yield put(ProgressActions.progressLoaded());
}

export function* loadFullProgressFromApi(startTimestamp?: number, endTimestamp?: number, userId?: number) {
  let progress: IBaseResult[] = [];
  let page = 0;
  let finished = false;
  while (!finished) {
    const result: TApiFetchResponse<typeof ProgressService.getFullProgress> = yield call(
      ProgressService.getFullProgress,
      startTimestamp,
      endTimestamp,
      page,
      userId
    );
    // if amount of loaded entries is less than limit - we loaded everything
    const fullProgressLoaded: boolean = yield call(isFullProgressPage, result.data.trainingprogress);
    if (!fullProgressLoaded) {
      finished = true;
    } else {
      // if we loaded number of entries equal to limit, probably there are more to load
      page += 1;
    }
    progress = [...progress, ...result.data.trainingprogress];
  }
  return progress;
}

export function* saveProgressSaga(action: PayloadAction<ISaveProgressPayload>) {
  const { variant, version, cycleId, sessionId, workoutId, progress } = action.payload;
  // create object for saving to backend marked unsynchronized
  const localResult: ILocalResult = yield call(
    resultBuilder,
    variant,
    version,
    cycleId,
    sessionId,
    workoutId,
    progress
  );
  // update locally stored progress history
  yield call(updateLocalHistory, localResult);
  // initiate saving process
  yield put(ProgressActions.startSaveProgress());
}

export function* saveScoreSaga(action: PayloadAction<ISaveProgressScorePayload>) {
  const { variant, version, cycleId, sessionId, workoutId, score } = action.payload;
  // create object for saving to backend marked unsynchronized
  const localResult: ILocalResult = yield call(scoreBuilder, variant, version, cycleId, sessionId, workoutId, score);
  // update locally stored progress history
  yield call(updateLocalHistory, localResult);
  yield put(ProgressActions.startSaveProgress());
}

export function* updateLocalHistory(progress: ILocalResult) {
  // merge to existing progress
  const existingProgress: ILocalResult[] = yield select(Selectors.getFullProgress);
  const mergedProgress = [...existingProgress, progress];
  // update state
  yield put(ProgressActions.updateProgress(mergedProgress));
  // TODO: update "last" state
  // update cache
  yield call(ProgressDb.saveProgress, mergedProgress);
}

export function* startSaveProgressSaga() {
  // get current queue of progress data
  const queue: ILocalResult[] = yield select(Selectors.getProgressToSync);
  if (queue.length === 0) {
    // nothing to save
    yield put(ProgressActions.endSaveProgress());
    return;
  }
  // convert data from local structure to server format
  const toSave: IBaseResult[] = yield call(prepareProgressForSaving, queue);
  try {
    // try to save it to backend
    yield call(ProgressService.saveProgress, toSave);
    // mark data as synchronized and save to cache
    yield call(markCacheAsSynchronized, queue);
    // mark data as synchronized in state
    yield call(markStateAsSynchronized, queue);
  } catch {
    // do nothing in case of error - we will save full progress after next workout
    // all workouts are saved in cache anyways
  }
  yield put(ProgressActions.endSaveProgress());
}

export function* markCacheAsSynchronized(savedResults: ILocalResult[]) {
  const cacheValue: IProgressCacheValue | undefined = yield call(ProgressDb.getProgress);
  if (!cacheValue) {
    // if cache is empty - something went wrong and we should not touch it
    return;
  }
  // mark given result as synchronized
  const synchronizedData: ILocalResult[] = yield call(markDataAsSynchronized, cacheValue.progress, savedResults);
  yield call(ProgressDb.saveProgress, synchronizedData);
}

export function* markStateAsSynchronized(savedResults: ILocalResult[]) {
  const progress: ILocalResult[] = yield select(Selectors.getFullProgress);
  const synchronizedData: ILocalResult[] = yield call(markDataAsSynchronized, progress, savedResults);
  yield put(ProgressActions.updateProgress(synchronizedData));
}

// we do not use cache for progress history entries as this is the shortcut
// for faster loading of the fresh user data, after full progress is loaded - this will not be used
export function* loadProgressHistorySaga(action: PayloadAction<ILoadProgressHistoryPayload>) {
  const { startTimestamp, endTimestamp, userId } = action.payload;
  const apiProgress: IBaseResult[] = yield call(loadFullProgressFromApi, startTimestamp, endTimestamp, userId);
  yield put(ProgressActions.updateProgressHistory(startTimestamp, endTimestamp, apiProgress, userId));
}

export function* loadLastProgressFromApi(workoutIds: number[], userId?: number) {
  try {
    const apiResult: TApiFetchResponse<typeof ProgressService.getWorkoutsLastProgress> = yield call(
      ProgressService.getWorkoutsLastProgress,
      workoutIds,
      userId
    );
    return apiResult.data.trainingprogress;
  } catch {
    return [];
  }
}

// we do not use cache for last progress entries as this is the shortcut
// for faster loading of the fresh user data, after full progress is loaded - this will not be used
export function* loadLastProgressSaga(action: PayloadAction<ILoadLastProgressPayload>) {
  const { workoutIds, userId } = action.payload;
  // TODO: check if progress already loaded - this action might be dispatched from different places
  // in the same loading flow, so some data may be called twice
  const apiProgress: IBaseResult[] = yield call(loadLastProgressFromApi, workoutIds, userId);
  yield put(ProgressActions.updateWorkoutLastProgress(workoutIds, apiProgress, userId));
}

export function* loadWorkoutsProgressFromApi(workoutIds: number[], userId?: number) {
  try {
    let progress: IBaseResult[] = [];
    let page = 0;
    let finished = false;
    while (!finished) {
      const result: TApiFetchResponse<typeof ProgressService.getMultipleWorkoutProgress> = yield call(
        ProgressService.getMultipleWorkoutProgress,
        workoutIds,
        PROGRESS_HISTORY_LOADING_LIMIT,
        page,
        userId
      );
      // if amount of loaded entries is less than limit - we loaded everything
      const fullProgressLoaded: boolean = yield call(isFullProgressPage, result.data.trainingprogress);
      if (!fullProgressLoaded) {
        finished = true;
      } else {
        // if we loaded number of entries equal to limit, probably there are more to load
        page += 1;
      }
      progress = [...progress, ...result.data.trainingprogress];
    }
    return progress;
  } catch (error) {
    return [];
  }
}

export function* loadWorkoutsProgressSaga(action: PayloadAction<ILoadworkoutProgressPayload>) {
  const { workoutIds, userId } = action.payload;
  const apiProgress: IBaseResult[] = yield call(loadWorkoutsProgressFromApi, workoutIds, userId);
  const workoutIdGroups = groupProgressByWorkout(apiProgress);
  for (const workoutId of workoutIds) {
    // sort it by timestamp
    const workoutProgress = sortProgress(workoutIdGroups[workoutId] ?? []);
    yield put(ProgressActions.updateWorkoutProgress(workoutId, workoutProgress, userId));
  }
}

export function* cleanProgressCacheSaga() {
  let retry = 0;
  let deleted = false;
  while (!deleted && retry < 5) {
    try {
      const { deleteCall } = yield race({
        deleteCall: call(ProgressDb.clear),
        timeout: delay(500),
      });
      deleted = !!deleteCall;
    } catch (error) {
      deleted = false;
    }
    retry += 1;
  }
  yield put(ProgressActions.cleanProgressDone());
}

export default function* progressWatcher() {
  yield all([
    takeEvery(ProgressActions.initProgress, initProgressSaga),
    takeEvery(ProgressActions.loadProgress, loadProgressSaga),
    takeEvery(ProgressActions.saveProgress, saveProgressSaga),
    takeEvery(ProgressActions.saveProgressScore, saveScoreSaga),
    // cancel previous saga when getting a new one action
    takeLatest(ProgressActions.startSaveProgress, startSaveProgressSaga),
    takeEvery(ProgressActions.loadProgressHistory, loadProgressHistorySaga),
    takeEvery(ProgressActions.loadLastProgress, loadLastProgressSaga),
    takeEvery(ProgressActions.loadWorkoutsProgress, loadWorkoutsProgressSaga),
    takeLeading(ProgressActions.cleanProgress, cleanProgressCacheSaga),
  ]);
}
