// eslint-disable-next-line @nx/enforce-module-boundaries
import { filterOnlyReadAndNeverTriggerMessages, InAppMessage, sortInAppMessages } from '@iterable/web-sdk';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import { formatDate, unixTimestampToDate } from '../../common/dateUtils';
import { AuthSelectors } from '../auth';
import Service from './service';
import { IterableActions } from './slice';
import { ISetMessageClickedPayload } from './types';

export function* initIterable() {
  try {
    const userEmail: string = yield select(AuthSelectors.getUserEmail);
    const userId: number = yield select(AuthSelectors.getUserId);
    yield call(Service.init, ApiService);
    yield call(Service.setUserDetails, userEmail, userId);
    yield call(Service.initIterable);
    yield put(IterableActions.iterableStarted());
  } catch (error) {
    // Handle error
    console.log('Could not initialize Iterable service', error);
  }
}

export function* getMessagesSaga() {
  const messages: Partial<InAppMessage>[] = yield call(Service.getMessages);
  yield put(IterableActions.storeMessages(sortInAppMessages(filterOnlyReadAndNeverTriggerMessages(messages))));
}

export function* setMessageReadSaga(action: PayloadAction<string>) {
  const messageId = action.payload;
  yield call(Service.setMessageRead, messageId);
}

export function* setMessageClickedSaga(action: PayloadAction<ISetMessageClickedPayload>) {
  const messageId = action.payload.messageId;
  const buttonId = action.payload.buttonId;
  yield call(Service.setMessageClicked, messageId, buttonId);
}

export function* trackDashboardOpenSaga() {
  const timestamp: Date = yield call(unixTimestampToDate);
  try {
    yield call(Service.trackEvent, 'web_dashboardVisited', {
      lastWebDashboardVisit: formatDate(timestamp, { dateStyle: 'short' }),
    });
  } catch {
    // ignore the issue for now
  }
}

export default function* iterableSagaWatcher() {
  yield all([
    takeEvery(IterableActions.initIterable, initIterable),
    takeEvery(IterableActions.getMessages, getMessagesSaga),
    takeEvery(IterableActions.setMessageRead, setMessageReadSaga),
    takeLeading(IterableActions.setMessageClicked, setMessageClickedSaga),
    takeLeading(IterableActions.trackDashboardOpen, trackDashboardOpenSaga),
  ]);
}
