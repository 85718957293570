import { AttributionService, LanguageService } from '@web_opxp/features';
import { createBrowserHistory } from 'history';
import { sessionStorage } from 'web_core_library';
import blogUrls from './blogUrls.json';
import {
  BasePath,
  BlogBaseUrl,
  LoginBaseUrl,
  LogoutBaseUrl,
  MobileAppLink,
  PrivacyPolicyUrl,
  RegistrationBaseUrl,
  ShopBaseUrl,
  SubscribeBaseUrl,
  TrainingBaseUrl,
  UserAppPath,
} from './env';
import EAppRoutes, { EEvaluationRoutes } from './routes';

const history = createBrowserHistory({
  basename: BasePath,
});

export function addListener(listener: (path: string) => void): () => void {
  const currentPath = history.createHref(history.location);
  listener(currentPath);
  return history.listen((location) => {
    const path = history.createHref(location);
    listener(path);
  });
}

export const getNormalizedUrl = (url: URL) => {
  let normalizedUrl = url.toString();
  if (normalizedUrl.indexOf('/?') < 0 && normalizedUrl.indexOf('?') >= 0) {
    normalizedUrl = normalizedUrl.replace('?', '/?');
  }
  return normalizedUrl;
};

export const getExternalUrl = (url: string, lang?: string, session?: string) => {
  // get current origin
  const base = window.location.origin;
  // base will be applied only if url is relative
  const resultUrl = new URL(url, base);

  if (lang) {
    resultUrl.searchParams.set('lang', lang);
  }
  if (session) {
    resultUrl.searchParams.set('session', session);
  }
  AttributionService.updateSearchParams(resultUrl.searchParams);
  return getNormalizedUrl(resultUrl);
};

export const getInternalUrl = (pathname?: string, search?: string) => {
  return history.createHref({ pathname, search });
};

export const redirectExternal = (url?: string, lang?: string, session?: string) => {
  if (!url) {
    return;
  }
  const redirectUrl = getExternalUrl(url, lang, session);
  return window.location.assign(redirectUrl);
};

export const redirectToTraining = (language?: string, session?: string) =>
  redirectExternal(TrainingBaseUrl, language, session);

export const redirectToSubscribe = (language?: string, session?: string) =>
  redirectExternal(SubscribeBaseUrl, language, session);

export const redirectInternal = (url: string, state = {}, search?: string) => {
  return history.push({ pathname: url, state, search });
};

export const redirectToEvaluation = (page = `${EAppRoutes.Evaluation}${EEvaluationRoutes.Intro}`) => {
  redirectInternal(page, {}, getCurrentSearchParams().toString());
};

export const getBackUrl = (path?: string, search?: string) => {
  // Don't change the following to ?? because path can be empty string
  const backUrlPath = path || history.location.pathname;
  const internalHref = getInternalUrl(backUrlPath, search);
  const backUrl = new URL(internalHref, window.location.origin);
  // add attribution tracking parameters in internal format for propagation
  AttributionService.updateSearchParams(backUrl.searchParams);
  return getNormalizedUrl(backUrl);
};

export const getSsoRedirectUrl = (
  language?: string,
  signup = false,
  backUrlPath?: string,
  logout?: boolean,
  signin?: string
) => {
  let redirectUrl;
  if (logout) {
    redirectUrl = new URL(LogoutBaseUrl);
  } else if (!signup) {
    redirectUrl = new URL(LoginBaseUrl);
  } else {
    redirectUrl = new URL(RegistrationBaseUrl);
  }
  const backUrl = getBackUrl(backUrlPath);
  redirectUrl.searchParams.set('backUrl', backUrl);
  if (language) {
    redirectUrl.searchParams.set('lang', language);
  }

  if (logout) {
    redirectUrl.searchParams.set('logout', '1');
  } else if (signin) {
    redirectUrl.searchParams.set('signin', signin);
  }
  // add attribution tracking parameters in internal format for propagation
  AttributionService.updateSearchParams(redirectUrl.searchParams);
  return getNormalizedUrl(redirectUrl);
};

export const redirectToSSO = (backUrlPath?: string, logout?: boolean, signin?: string, signup = false) => {
  const language = LanguageService.getLanguage();
  const redirectUrl = getSsoRedirectUrl(language, signup, backUrlPath, logout, signin);
  sessionStorage.remove('session');
  return window.location.assign(redirectUrl);
};

export function redirectLogout(backUrlPath = '') {
  return redirectToSSO(backUrlPath, true, '', false);
}

export function exchangeAccessToken(accessToken: string) {
  return redirectToSSO('', false, accessToken);
}

export function redirectLogin(backUrlPath?: string) {
  return redirectToSSO(backUrlPath, false, '', false);
}

export function redirectSignup(backUrlPath?: string) {
  return redirectToSSO(backUrlPath, false, '', true);
}

export function redirectMobileStore() {
  return redirectExternal(MobileAppLink);
}

export function getZendeskUrl(language: string, session?: string) {
  const locale = language === 'en' ? 'en-Us' : language;
  const tokenParam = session ? `?signin=${session}` : '';
  return `${process.env.NX_PUBLIC_API_URL}/zendesk/${locale}${tokenParam}`;
}

export enum EBlogUrls {
  Blog = 'blog',
  Studies = 'studies',
  Projects = 'projects',
  Effectiveness = 'effectiveness',
  Extra1 = 'extra1',
  Extra2 = 'extra2',
  Extra3 = 'extra3',
  Extra4 = 'extra4',
  Extra5 = 'extra5',
}

export const getBlogUrl = (lang?: string, path = EBlogUrls.Blog) => {
  let blogPath = '';
  switch (lang) {
    case 'en':
      blogPath = blogUrls[path].en;
      break;
    case 'de':
      blogPath = blogUrls[path].de;
      break;
    case 'fr':
      blogPath = blogUrls[path].fr;
      break;
    case 'ru':
      blogPath = blogUrls[path].ru;
      break;
  }
  const blogUrl = new URL(`${BlogBaseUrl}${blogPath}`);
  AttributionService.updateSearchParams(blogUrl.searchParams);
  return blogUrl.toString();
};

export const getEffectivenessUrl = (lang: string) => getBlogUrl(lang, EBlogUrls.Effectiveness);
export const getStudiesUrl = (lang: string) => getBlogUrl(lang, EBlogUrls.Studies);
export const getProjectsUrl = (lang: string) => getBlogUrl(lang, EBlogUrls.Projects);

export const getPrivacyPolicyUrl = (lang: string) => {
  const url = new URL(PrivacyPolicyUrl);
  url.searchParams.set('lang', lang);
  AttributionService.updateSearchParams(url.searchParams);
  return url.toString();
};

export type Location = typeof history.location;

export const getCurrentSearchParams = (location?: Location) => {
  const search = location ? location.search : history.location.search;
  return new URLSearchParams(search);
};

export const updateSearchParams = (newSearch: URLSearchParams) => {
  const location = history.location;
  const newSearchQuery = `?${newSearch.toString()}`;
  if (location.search !== newSearchQuery) {
    location.search = newSearchQuery;
    history.replace(location);
  }
};

export const getMigrationUrl = (lang: string, session?: string) =>
  getExternalUrl(`${UserAppPath}/switch-back`, lang, session);

export const redirectMigration = (lang: string, session?: string) =>
  redirectExternal(`${UserAppPath}/switch-back`, lang, session);

export const getRecruitmentLink = () => {
  const blogUrl = new URL('/de/studienteilnehmer-innen-fuer-studie-gesucht/', BlogBaseUrl);
  AttributionService.updateSearchParams(blogUrl.searchParams);
  return blogUrl.toString();
};

export const getMedRecruitmentLink = () => {
  const medUrl = new URL(
    'https://neuronation-med.de/?utm_source=neuronation&utm_medium=banner&utm_campaign=top-banner'
  );
  // attribution can be added here if needed
  return medUrl.toString();
};

export const refreshToken = () => {
  return redirectLogin();
};

export const getObFlow = (location?: Location) => {
  const searchParams = getCurrentSearchParams(location);
  return searchParams.get('ob_flow');
};

export const getObAwareInternalUrl = (path = '/', location?: Location) => {
  let search: string | undefined;
  const searchParams = getCurrentSearchParams(location);
  const ob_flow = searchParams.get('ob_flow');
  if (ob_flow) {
    search = `?ob_flow=${ob_flow}`;
  }
  return getInternalUrl(path, search);
};

export const getWelcomeLink = (location?: Location) => getObAwareInternalUrl('/', location);

export const getLPLocaId = (location?: Location) => {
  const searchParams = getCurrentSearchParams(location);
  return searchParams.get('loca');
};

export const getPersonalization = (location?: Location) => {
  const searchParams = getCurrentSearchParams(location);
  return searchParams.has('p13n');
};

export const getSubscribeUrl = (lang?: string, session?: string, coupon?: string) => {
  let subscribeUrl = SubscribeBaseUrl;
  if (coupon) {
    subscribeUrl = `${SubscribeBaseUrl}/?coupon=${coupon}`;
  }
  return getExternalUrl(subscribeUrl, lang, session);
};

export const getAssessmentUrl = () => {
  return getInternalUrl('/assessment');
};

export const redirectToAssessment = () => redirectInternal(getAssessmentUrl());

export const stripLastSlash = (str: string) => {
  return str.replace(/\/$/, '');
};

export const getLumositySubpage = (location?: Location) => {
  const currentPath = location ? location.pathname : history.location.pathname;
  switch (stripLastSlash(currentPath)) {
    case '/lp/go/science':
      return 'science';
    case '/lp/go/exercises':
      return 'exercises';
    default:
      return 'home';
  }
};

export const getCorpSubpage = (location?: Location) => {
  const currentPath = location ? location.pathname : history.location.pathname;
  switch (stripLastSlash(currentPath)) {
    case '/lp/corp/science':
      return 'science';
    case '/lp/corp/exercises':
      return 'exercises';
    default:
      return 'home';
  }
};

export const isLumosityHome = (location?: Location) => {
  const currentPath = location ? location.pathname : history.location.pathname;
  return stripLastSlash(currentPath) === '/lp/go';
};

export const getShopUrl = (productId: number, lang: string, session?: string) =>
  getExternalUrl(`${ShopBaseUrl}/?productId=${productId}`, lang, session);

export const getJobsUrl = () => {
  const jobsUrl = new URL('https://neuronation.breezy.hr/');
  return jobsUrl.toString();
};

export const getGroupPackageUrl = () => {
  const groupPackageUrl = getInternalUrl('/lp/corp');
  return groupPackageUrl.toString();
};

export default history;
