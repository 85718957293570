import * as IterableReducer from './slice';

export interface IAppState {
  // this feature state
  [IterableReducer.statePropName]: IterableReducer.IIterableState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getState = (state: IAppState) => state[IterableReducer.statePropName];

export const isStarted = (state: IAppState) => getState(state).started;

export const getMessages = (state: IAppState) => getState(state).messages;

export const getMessagesForTrigger = (state: IAppState, trigger: string) =>
  getState(state).messages.filter((message) => message.triggers === trigger);

export const getNextMessage = (state: IAppState, trigger: string) => getMessagesForTrigger(state, trigger).shift();
