import { Icon } from '@blueprintjs/core';
import React from 'react';
import './PasswordInput.scss';
import StringInput from './StringInput';

export enum EInputColor {
  Light = 'light',
  Dark = 'dark',
}

export interface IPasswordInputProps {
  autofocus?: boolean;
  className?: string;
  color?: EInputColor;
  error?: string;
  initialValue?: string;
  name?: string;
  placeholder: string;
  required?: boolean;
  onChange: (value: string) => void;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({
  autofocus,
  className,
  color = EInputColor.Dark,
  error,
  initialValue,
  name,
  placeholder,
  required,
  onChange,
}) => {
  const errorClass = error ? ' is-invalid' : '';
  const [showPassword, setShowPassword] = React.useState(false);
  const togglePassword = () => setShowPassword(!showPassword);
  const passwordIcon = showPassword ? `eye-off` : `eye-open`;
  return (
    <StringInput
      autocomplete="password"
      autofocus={autofocus}
      className={`password-input ${className}`}
      color={color}
      error={error}
      initialValue={initialValue}
      name={name}
      placeholder={placeholder}
      required={required}
      type={showPassword ? 'text' : 'password'}
      onChange={onChange}>
      <div className={`eye-icon-container${errorClass}`}>
        <Icon icon={passwordIcon} className="eye-icon" onClick={togglePassword} />
      </div>
    </StringInput>
  );
};

export default PasswordInput;
