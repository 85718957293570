import { IObFlowStep, ObFlowServiceOptions, TObFlowApp } from './types';
import * as Utils from './utils';

export function createObFlowService() {
  let _currentApp: TObFlowApp;
  let _searchParams: URLSearchParams;
  let _options: ObFlowServiceOptions;
  let _assetsBaseUrl: string;

  function init(
    currentAppName: TObFlowApp,
    curentSearchParams: URLSearchParams,
    assetsBaseUrl: string,
    options: ObFlowServiceOptions
  ) {
    _currentApp = currentAppName;
    _searchParams = curentSearchParams;
    _options = options;
    _assetsBaseUrl = assetsBaseUrl;
  }

  const isReady = () => !!_currentApp;

  const getAssetsBaseUrl = () => _assetsBaseUrl;

  /**
   * function returns definition of the next step
   * consumer can decide how and where to redirect the user
   * @param currentStepName name of the current step
   */
  function getNextObStep(currentStepName?: string) {
    if (!isReady()) {
      return null;
    }
    return Utils.getNextObStep(currentStepName, _searchParams);
  }

  function getNextObUrl(nextObStep: IObFlowStep | null, language?: string, session?: string) {
    if (!isReady()) {
      // TODO: check if we can throw an error here or is it better to just return an empty string
      return '';
    }
    const { getExternalUrl, getInternalUrl, getRegistrationUrl, urlMap } = _options;
    if (nextObStep === null) {
      const dashboardUrl = getExternalUrl(urlMap['training'], language, session);
      return dashboardUrl;
    }
    const { app, name, path } = nextObStep;
    if (app === _currentApp) {
      const internalUrl = getInternalUrl(path, `?${_searchParams.toString()}`);
      return internalUrl;
    }
    if (app === 'sso') {
      // get step after the sso where we should return from sso
      const returnObStep = Utils.getReturnObStep(name, _searchParams);
      const backUrl = getNextObUrl(returnObStep, language, session);
      const signupUrl = getRegistrationUrl(backUrl, language);
      return signupUrl;
    }
    const appUrl = urlMap[app];
    return getExternalUrl(`${appUrl}${path}`, language, session);
  }

  function getUrlForNextStep(currentStepName?: string, language?: string) {
    const nextObStep = getNextObStep(currentStepName);
    const nextStepUrl = getNextObUrl(nextObStep, language);
    return nextStepUrl;
  }

  function redirectToNextObStep(currentStepName?: string, language?: string, session?: string) {
    if (!isReady()) {
      // TODO: check if we can throw an error here or is it better to just return nothing
      return;
    }
    const nextObStep = getNextObStep(currentStepName);
    const { urlMap, redirectExternal, redirectInternal, redirectRegistration } = _options;
    if (nextObStep === null) {
      redirectExternal(urlMap['training'], language, session);
      return;
    }
    const { app, name, path } = nextObStep;
    if (app === _currentApp) {
      redirectInternal(path);
      return;
    }
    if (app === 'sso') {
      // get step after the sso where we should return from sso
      const returnObStep = Utils.getReturnObStep(name, _searchParams);
      const backUrl = getNextObUrl(returnObStep, language, session);
      redirectRegistration(backUrl);
      return;
    }
    const appUrl = urlMap[app];
    redirectExternal(`${appUrl}${path}`, language, session);
  }

  function isNextStepInCurrentApp(nextObStep: IObFlowStep | null) {
    if (nextObStep === null) {
      return _currentApp === 'training';
    }
    return nextObStep.app === _currentApp;
  }

  function getObFlowProgress() {
    if (!isReady()) {
      return [];
    }
    return Utils.getObFlowProgress(_searchParams);
  }

  function getActiveProgress(currentStepName: string) {
    if (!isReady()) {
      return;
    }
    return Utils.getActiveProgress(currentStepName, _searchParams);
  }

  function getCompletedProgress(currentStepName: string) {
    if (!isReady()) {
      return [];
    }
    return Utils.getCompletedProgress(currentStepName, _searchParams);
  }

  /**
   * function returns variant of the current step defined in the flow
   * @param currentStepName name of the current step
   */
  function getCurrentObStepVariant(currentStepName: string) {
    if (!isReady()) {
      return;
    }
    return Utils.getCurrentObStepVariant(currentStepName, _searchParams);
  }

  function isStepPresentInTheFlow(stepName: string) {
    if (!isReady()) {
      return false;
    }
    return Utils.isStepPresentInTheFlow(stepName, _searchParams);
  }

  return {
    getActiveProgress,
    getAssetsBaseUrl,
    getCompletedProgress,
    getCurrentObStepVariant,
    getNextObStep,
    getNextObUrl,
    getObFlowProgress,
    getUrlForNextStep,
    init,
    isNextStepInCurrentApp,
    isReady,
    isStepPresentInTheFlow,
    redirectToNextObStep,
  };
}

const ObFlowService = createObFlowService();
export default ObFlowService;
