import * as React from 'react';
import NavBar from '../navBar';
import BurgerIcon from './BurgerIcon';
import CloseIcon from './CloseIcon';
import './Header.scss';

export interface IHeaderProps {
  logoLink?: string;
  logoSrc?: string;
  logoLabel?: string;
}
const DefaultLogoSrc = 'https://cms.cdn.neuronation.com/assets/opxp/logo.svg';

export const Header: React.FC<React.PropsWithChildren<IHeaderProps>> = ({
  logoLink,
  logoSrc = DefaultLogoSrc,
  logoLabel = 'NeuroNation',
  children,
}) => {
  const [isOpen, openMenu] = React.useState(false);
  const buttonClickHandler = () => openMenu(!isOpen);
  const buttonIcon = isOpen ? <CloseIcon /> : <BurgerIcon />;
  const headerClass = `header ${isOpen ? 'open' : 'closed'}`;
  const logo = logoLink ? (
    <a href={logoLink} className="logo-link">
      <img src={logoSrc} className="header-logo" alt={logoLabel} />
    </a>
  ) : (
    <span className="logo-link">
      <img src={logoSrc} className="header-logo" alt={logoLabel} />
    </span>
  );
  const isEmpty = !children;
  const toggleButton = isEmpty ? null : (
    <button type="button" className="nav-toggle" onClick={buttonClickHandler}>
      {buttonIcon}
    </button>
  );
  const navMenu = isEmpty ? null : (
    <div className="header-collapsible">
      <NavBar className="header-nav">{children}</NavBar>
    </div>
  );
  return (
    <header className={headerClass}>
      <div className="container">
        <div className="header-common">
          {logo}
          {toggleButton}
        </div>
        {navMenu}
      </div>
    </header>
  );
};

export default Header;
