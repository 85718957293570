import { CircleProgressBar, IProgressItemProps, ProgressItem, ProgressItemState } from '@web_opxp/components';
import { useTranslation } from '../language';
import ObFlowService from './service';

interface IOnboardingFlowProgressProps {
  // current step in the ob_flow
  currentStep: string;
  // current and max values to render progress like 3/5 inside the progress item
  current?: number;
  max?: number;
}

const OBFlowProgressItemsMap: { [name: string]: IProgressItemProps } = {
  intro: {
    name: 'intro',
    label: 'opxp.common:Progress.Intro',
  },
  questionnaire: {
    name: 'questionnaire',
    label: 'opxp.common:Progress.Goal',
  },
  assessment: {
    name: 'assessment',
    label: 'opxp.common:Progress.Assessment',
  },
  evaluation: {
    name: 'evaluation',
    label: 'opxp.common:Progress.Evaluation',
  },
  trainingplan: { name: 'trainingplan', label: 'opxp.common:Progress.Trainingplan' },
};

export default function OnboardingFlowProgress({ currentStep, current, max }: IOnboardingFlowProgressProps) {
  const { t } = useTranslation();
  if (!ObFlowService.isReady()) {
    return null;
  }
  const assetsBaseUrl = ObFlowService.getAssetsBaseUrl();
  const checkIconSrc = `${assetsBaseUrl}/feature/exercise-icons/icon-checked.png`;
  // get all progress items to render
  const progressNames = ObFlowService.getObFlowProgress();
  // get items to be marked as completed
  const completedItems = ObFlowService.getCompletedProgress(currentStep);
  // get item to be marked as active
  const activeItem = ObFlowService.getActiveProgress(currentStep);
  // render items
  const items = progressNames.map((itemName) => {
    const item: IProgressItemProps = {
      ...OBFlowProgressItemsMap[itemName],
    };
    item.label = t(item.label);
    if (completedItems.includes(itemName)) {
      item.state = ProgressItemState.Done;
      item.iconSrc = checkIconSrc;
    }
    if (itemName === activeItem) {
      item.state = ProgressItemState.Active;
      item.progress = 1;
      if (typeof current !== 'undefined' && typeof max !== 'undefined') {
        if (current <= max) {
          item.progress = current / max;
          item.value = `${current}/${max}`;
        } else {
          // if current > max we set item as completed
          item.state = ProgressItemState.Done;
          item.iconSrc = checkIconSrc;
        }
      }
    }
    return <ProgressItem key={itemName} {...item} />;
  });
  return <CircleProgressBar>{items}</CircleProgressBar>;
}
