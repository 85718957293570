import * as React from 'react';
import { Link } from 'react-router-dom';

import './AnchorButton.scss';

export enum EAnchorButtonColor {
  white = 'nn-anchorbutton__white',
  blue = 'nn-anchorbutton__blue',
  green = 'nn-anchorbutton__green',
  red = 'nn-anchorbutton__red',
}

export enum EAnchorButtonTag {
  a = 'a',
  link = 'Link',
}

export const CAnchorButtonClassname = 'nn-anchorbutton';

export interface IAnchorButtonProps {
  href: string;
  tag?: EAnchorButtonTag;
  color?: EAnchorButtonColor;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  className?: string;
}

const AnchorButton: React.FC<React.PropsWithChildren<IAnchorButtonProps>> = ({
  href,
  tag = EAnchorButtonTag.a,
  color = EAnchorButtonColor.blue,
  disabled,
  onClick,
  className = '',
  children,
}) => {
  const anchorClass = `${CAnchorButtonClassname} ${color} ${className} ${disabled ? 'disabled' : ''}`;
  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (disabled) {
      event.preventDefault();
    } else {
      onClick && onClick(event);
    }
  };
  if (tag === EAnchorButtonTag.link) {
    return (
      <Link to={href} className={anchorClass} onClick={onClickHandler}>
        {children}
      </Link>
    );
  }
  return (
    <a href={href} className={anchorClass} onClick={onClickHandler}>
      {children}
    </a>
  );
};

export default AnchorButton;
