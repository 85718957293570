import { InAppMessage } from '@iterable/web-sdk';
import { IInAppMessageBase } from './types';

export const processInAppMessage = (message: Partial<InAppMessage>) => {
  let content: string | undefined = undefined;
  if (message.content?.html) {
    if (typeof message.content.html === 'string') {
      content = message.content.html;
    } else {
      content = `<iframe src="${message.content.html.src}"></iframe>`;
    }
  }
  const processedMessage: IInAppMessageBase = {
    messageId: message.messageId,
    html: content,
    priority: message.priorityLevel,
    triggers: message.customPayload?.triggers ?? 'dashboard',
    read: message.read,
  };
  return processedMessage;
};

export function removeSlashes(path: string): string {
  return path.replace(/^\/|\/$/g, '');
}
